// Required where Bootstrap variables are used - SASS only imports what variables are used so no impact to file size.
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@function get-breakpoints($key: "md") {
    @return map-get($container-max-widths, $key); }

.launches {
	.table {
		@include media-breakpoint-down(sm) {
			font-size: 0.7em; }

		th {
			line-height: 0.8;
			border: 0;
			border-bottom: 1px solid white;
			.note {
				font-size: 0.6em; } }

		tbody {
			.launchRow {
				&:hover {
					background: $gray-900;
					cursor: pointer; }
				img {
					height: 4.5rem; } } }

		.rounded-circle {
			height: 4.5rem; }

		.launchDropdown {
			padding: 0;
			.ReactCollapse--collapse {
				position: sticky;
				left: 0;
				max-width: calc(100vw - 30px);
				@include media-breakpoint-up(sm) {
					max-width: get-breakpoints("sm") - 30; }
				@include media-breakpoint-up(md) {
					max-width: get-breakpoints("md") - 30; }
				@include media-breakpoint-up(lg) {
					max-width: get-breakpoints("lg") - 30; }
				@include media-breakpoint-up(xl) {
					max-width: get-breakpoints("xl") - 30; }
				.wrapper {
					background: $gray-900;
					h5 {
						text-decoration: underline; } } } } } }
