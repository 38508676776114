@import "./node_modules/bootstrap/scss/bootstrap";

body {
	font-family: "Space Mono", monospace;
	margin: 0;
	padding: 0;

	a {
		transition: 0.3s;
		color: $gray-600;
		text-decoration: underline;

		&.active {
			color: $gray-500; }

		&:hover {
			color: $gray-500; }

		&:focus {
			outline-color: $gray-500; } } }


.arrow {
	height: 2.5em;
	cursor: pointer;
	.up {
		opacity: 0; }
	.arrow-fade {
		transition: opacity 0.3s;
		&-enter {
			opacity: 1;
			&-done {
				opacity: 1; } }
		&-exit {
			opacity: 0;
			&-done {
				opacity: 0; } } } }

@import url(https://fonts.googleapis.com/css?family=Space+Mono);
